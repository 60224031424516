.container {
  width: 100%;
  min-height: 100vh;
  padding-top: 120px;
  max-width: 550px;
  margin: 0 auto;
}

.header {
  font-family: Jost;
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 100%;
  color: var(--black);
  text-align: left;
  width: 100%;
  max-width: 1024px;
  padding: 0 26px;
  margin: 0 auto;
  margin-bottom: 63px;
}

.posts {
  width: 100%;
  margin: 60px auto 100px;
}

.post {
  position: relative;
  margin: 0 auto 60px;
  padding: 40px 26px;
  width: 100%;
  background: var(--white);
  box-shadow: 0px 22px 94px rgba(0, 0, 0, 0.08);
}

.actionBtnEmpty,
.actionBtn {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
  text-align: center;

  font-family: Jost;
  font-weight: 600;
  font-size: 16px;
  padding: 16px 26px;
  flex-shrink: 0;
  background: var(--yellow);
  color: var(--black);
}

.actionBtnEmpty {
  position: relative;
  padding: 12px 26px;
  margin-top: 40px;
  width: max-content;
}

.subTitle,
.title {
  max-width: 900px;
  margin-bottom: 32px;
  position: relative;
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 100%;
  color: #000000;
}

.subTitle {
  font-size: 20px;
  width: 100%;
  font-weight: 500;
  margin-bottom: 18px;
}

.desc {
  max-width: 900px;
  font-family: Tenor Sans;
  font-size: 16px;
  line-height: 140%;
  color: var(--black80);
  margin-bottom: 60px;
}

.tags {
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.tag {
  word-break: break-word;
  display: block;
  font-family: Jost;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  border: 2px solid var(--yellow);
  color: var(--black);
  padding: 6px 12px;
  background: var(--white);
  margin: 0 12px 12px 0;
}

.emptyState {
  padding: 0 26px;
  margin-top: 100px;
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
}

.emptyState h2 {
  line-height: 110%;
  font-family: Jost;
  margin-bottom: 20px;
  font-weight: 500;
}

.emptyState .btn {
  position: relative;
  top: unset;
  right: unset;
  margin: 0 20px 20px 0;
}

.emptyState .btn:nth-child(2) {
  border: 2px solid var(--black);
  background: var(--black);
  color: var(--white);
}

@media (min-width: 1024px) {
  .container {
    padding-top: 160px;
    max-width: unset;
  }

  .header {
    font-size: 56px;
    text-align: left;
    padding: 0 26px;
  }

  .posts {
    width: 100%;
    margin: 60px auto 0;
    max-width: 1024px;
    padding: 0 26px;
    padding-bottom: 100px ;
  }

  .post {
    padding: 60px;
    min-width: 900px;
    max-width: 1024px;
  }

  .emptyState {
    padding: 0;
  }
}
