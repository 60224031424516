@import url(https://fonts.googleapis.com/css2?family=Cuprum:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Jost:wght@300;400;500;600;700;800;900&family=Tenor+Sans&display=swap);
html {
  min-width: 320px;
  background: #ffffff;
  background: var(--white);
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a,
button {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
}

a:active,
button:active {
  opacity: 0.6;
}

body {
  margin: 0;
  font-family:  'Tenor Sans', -apple-system,
    BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;
  background: var(--white);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grecaptcha-badge {
  visibility: hidden;
}

:root {
  --red: #ff593f;
  --red05: #ff593f20;
  --blue: #3245FE;
  --yellow: #FFC900;
  --green: #33AB79;
  --white: #ffffff;
  --white70: rgba(255, 255, 255, 0.7);
  --black: #000000;
  --black90: rgba(0, 0, 0, 0.9);
  --black80: rgba(0, 0, 0, 0.8);
  --black70: rgba(0, 0, 0, 0.7);
  --black60: rgba(0, 0, 0, 0.6);
  --black50: rgba(0, 0, 0, 0.5);
  --black40: rgba(0, 0, 0, 0.4);
  --black30: rgba(0, 0, 0, 0.3);
  --black20: rgba(0, 0, 0, 0.2);
  --black10: rgba(0, 0, 0, 0.1);
  --black05: rgba(0, 0, 0, 0.05);
  --black04: rgba(0, 0, 0, 0.04);
  --black03: rgba(0, 0, 0, 0.03);
  --black02: rgba(0, 0, 0, 0.02);
  --black01: rgba(0, 0, 0, 0.01);
  --bckgGray: #f8f8f8;
}

.Home_container__1s89M {
  width: 100%;
  line-height: 150%;
  font-size: 14px;
}

.Home_block__25LWw {
  width: 100%;
  margin-bottom: 100px;
  position: relative;
}

.Home_blockContainer__1CYrE {
  position: relative;
  width: 100%;
  padding: 0 16px;
  margin: 0 auto;
  margin-bottom: 100px;
  max-width: 550px;
}

.Home_start__2g6ZA {
  position: relative;
  margin-bottom: 0;
  height: 100vh;
  min-height: 680px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 100px;
}

.Home_startWrap__1BoeG {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: var(--white);
  display: none;
}

.Home_startContent__ZNYx3 {
  background: var(--white);
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 60px 20px 160px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.Home_startContent__ZNYx3 p {
  font-family: Jost;
  font-size: 18px;
  font-weight: 400;
  color: var(--black80);
  text-align: center;
  max-width: 500px;
}

.Home_startBackground__1yhU5 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  bottom: 170px;
  right: 0;
}

.Home_logo__3auvB {
  width: 160px;
  margin: 0 0 50px
}

.Home_blockHeader__15C0w {
  font-family: Jost;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 100%;
  color: var(--black);
  margin-bottom: 60px;
  text-align: center;
}

.Home_blockHeader__15C0w a {
  color: inherit;
  text-decoration: underline;
}

.Home_aboutImageDesk__2mml1 {
  display: none;
  width: 384px;
}

.Home_aboutImageMob__2cXoH {
  width: 284px;
  margin: 40px auto;
}

.Home_twoColText__iAGwa {
  display: grid;
  grid-auto-flow: row;
  grid-column-gap: 32px;
  -webkit-column-gap: 32px;
          column-gap: 32px;
}

.Home_twoColImg__KMtqP {
  display: block;
  margin: 60px auto;
  width: -webkit-max-content;
  width: max-content;
  max-width: 280px;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}

.Home_reverse__P0q0A {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

.Home_history__3cdxq {
  position: relative;
}

.Home_historyLine__36M9a {
  height: 100%;
  width: 6px;
  background: var(--yellow);
  position: absolute;
  top: 0;
  left: 28px;
}

.Home_historyBlock__A187O {
  display: grid;
  grid-auto-flow: row;
  margin-left: 60px;
  margin-bottom: 80px;
  position: relative;
}

.Home_historyBlock__A187O img {
  max-width: 180px;
  margin-bottom: 20px;
}

.Home_historyTitle__3w7XO {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 100%;
  margin-bottom: 24px;
  min-width: 110px;
  display: block;
  text-align: right;

  position: absolute;
  top: 0;
  -webkit-transform-origin: top right;
          transform-origin: top right;
  -webkit-transform: rotate(270deg) translateY(-175px);
          transform: rotate(270deg) translateY(-175px);
}

.Home_link__32aMM {
  display: block;
  width: -webkit-max-content;
  width: max-content;
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  margin-top: 40px;
  color: var(--black);
  padding: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-decoration: underline;
}

.Home_link__32aMM:hover {
  color: var(--yellow);
}

.Home_link__32aMM:hover .Home_linkArrow__1ngcq {
  background: url(/static/media/arrowY.617b0fd4.svg) no-repeat center;
}

.Home_link__32aMM:nth-of-type(2) {
  margin-top: 10px;
}

.Home_linkArrow__1ngcq {
  width: 20px;
  height: 20px;
  margin-left: 6px;
  background: url(/static/media/arrow.7ab53e0c.svg) no-repeat center;
  display: block;
}

.Home_fopBlock__2g1-E {
  position: unset;
}

.Home_fopBlock__2g1-E h2 {
  color: var(--white);
  position: absolute;
  top: 60px;
}

.Home_fopParkLongImg__SJdDQ {
  display: none;
}

.Home_fopParkShortImg__1h70v {
  width: 100%;
  height: 360px;
  object-fit: cover;
  margin-bottom: 20px;
}

.Home_fopMap__2ArkX {
  position: relative;
  display: none;
}

.Home_fopMap__2ArkX img {
  display: block;
  width: 300px;
  margin: 0 auto;
}

.Home_fopPointer__1sKdL {
  display: -webkit-flex;
  display: flex;

  font-family: Jost;
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  color: var(--black);

  position: absolute;
  top: 234px;
  left: 274px;
}

.Home_fopPointer__1sKdL > div {
  width: 20px;
  height: 20px;
  border: 6px solid var(--yellow);
  border-radius: 50%;
  margin-right: 10px;
}

.Home_fopAdvs__Cm_PE {
  margin: 80px 0;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 80px 32px;
  gap: 80px 32px;

  font-family: Jost;
  font-size: 14px;
  line-height: 140%;
  color: var(--black);
  text-align: center;
}

.Home_fopAdvs__Cm_PE > div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  max-width: 200px;
  margin: 0 auto;
}

.Home_fopAdvs__Cm_PE img {
  width: 80px;
  margin-bottom: 20px;
}

.Home_lead__eZVMT {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  margin-bottom: 60px;
}

.Home_lead__eZVMT img {
  width: 120px;
  margin-bottom: 20px;
}

.Home_leadName__20DlF {
  font-family: Jost;
  font-weight: 600;
  color: var(--black);
  font-size: 18px;
}

.Home_leadPosition__3jGIb {
  margin-top: 6px;
  font-family: Tenor Sans;
  font-size: 14px;
  color: var(--black40);
  text-align: center;
  line-height: 150%;
}

.Home_galleryBlock__1LX42,
.Home_artistsBlock__7Gc2d {
  margin-bottom: 80px;
}

.Home_accBlock__2LfPC {
  margin-bottom: 110px !important;
  padding: 0;
}

.Home_galleryBlockHeader__110Cf {
  width: 100%;
  padding: 0 24px;
  margin: 0 auto;
  font-family: Jost;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 100%;
  color: var(--black);
  margin-bottom: 40px;
}

.Home_photos__3zrAg {
  overflow-x: scroll;
  width: 100%;
  display: -webkit-flex;
  display: flex;
}

.Home_photo__1xAKH {
  width: 90vmin;
  max-width: 600px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  transition: 0.1s;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  margin-right: 2px;
  -webkit-filter: brightness(1.1);
          filter: brightness(1.1);
  z-index: 3;
}

.Home_accBtn__k2p8r {
  outline: none;
  font-family: Jost;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  color: var(--black);
  padding: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.Home_arrow__3dU9n {
  width: 20px;
  height: 20px;
  background: url(/static/media/arrow.7ab53e0c.svg) no-repeat center;
  background-size: 50%;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.Home_arrowUp__3R2YK {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.Home_accHead__ucq2j {
  background: var(--yellow);
}

.Home_accPanel___9fo2 {
  padding: 40px 20px;
  font-family: Jost;
  font-size: 16px;
  color: var(--black);
  background: var(--black02);
}

.Home_cell__1U4Pq {
  display: grid;
  grid-template-columns: 3fr 4fr;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  padding: 8px 0;
}

.Home_cell__1U4Pq span:first-child {
  text-align: right;
}

.Home_salary__GmUot {
  font-size: 14px;
  margin: 20px 0 40px;
  font-weight: 600;
}

.Home_panelText__2QU-e {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-family: Jost;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
  color: var(--black90);
  list-style: none;
}

.Home_panelText__2QU-e span,
.Home_panelText__2QU-e li {
  margin-bottom: 30px;
}

.Home_panelText__2QU-e > div {
  font-family: Jost;
  font-weight: 500;
  font-size: 18px;
  color: var(--black);
  margin: 30px auto;
}

.Home_smallText__2eCi2 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: var(--black40);
  line-height: 130%;
  margin-bottom: 0 !important;
}

.Home_disclaimer__3L8Lf {
  max-width: 690px;
  margin: 80px auto;
  font-family: Jost;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  padding: 16px;
}

.Home_actionBtn__aZzdq {
  font-family: Jost;
  font-weight: 600;
  font-size: 16px;
  padding: 12px 46px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  background: var(--yellow);
  color: var(--black);
  width: -webkit-max-content;
  width: max-content;
  margin-top: 40px;
}

@media (min-width: 768px) {
  .Home_accBtn__k2p8r {
    padding: 16px 40px;
  }

  .Home_accPanel___9fo2 {
    padding: 40px;
  }
}

@media (min-width: 1024px) {
  .Home_container__1s89M {
    width: 100%;
    line-height: 150%;
    font-size: 16px;
  }

  .Home_block__25LWw {
    width: 100%;
    margin-bottom: 250px;
    position: relative;
  }

  .Home_blockContainer__1CYrE {
    position: relative;
    width: 1024px;
    padding: 0 24px;
    margin: 0 auto;
    margin-bottom: 250px;
    max-width: unset;
  }

  .Home_start__2g6ZA {
    position: relative;
    margin-bottom: 0;
    height: 100vh;
    width: 100%;
    background: var(--yellow);
    overflow: hidden;
    margin-bottom: 250px;
  }

  .Home_startWrap__1BoeG {
    display: block;
    position: absolute;
    top: -20%;
    right: 55%;
    width: 100%;
    height: 300%;
    background: var(--white);
    -webkit-transform-origin: top right;
            transform-origin: top right;
    -webkit-transform: rotate(348deg);
            transform: rotate(348deg);
  }

  .Home_startContent__ZNYx3 {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 40px 40px 40px 8%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    max-width: 640px;
    background: transparent;
  }

  .Home_startContent__ZNYx3 p {
    font-family: Jost;
    font-size: 20px;
    font-weight: 400;
    color: var(--black80);
    text-align: left;
  }

  .Home_startBackground__1yhU5 {
    width: 65%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    right: 0;
  }

  .Home_logo__3auvB {
    width: 300px;
    margin: 0 0 60px
  }

  .Home_blockHeader__15C0w {
    font-family: Jost;
    font-style: normal;
    font-weight: 600;
    font-size: 46px;
    line-height: 100%;
    color: var(--black);
    margin-bottom: 80px;
  }

  .Home_aboutImageDesk__2mml1 {
    display: block;
    width: 384px;
  }

  .Home_aboutImageMob__2cXoH {
    display: none;
  }

  .Home_twoColText__iAGwa {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 32px;
    -webkit-column-gap: 32px;
            column-gap: 32px;
    margin: 54px 0;
  }

  .Home_history__3cdxq {
    position: relative;
  }

  .Home_historyLine__36M9a {
    height: 100%;
    width: 6px;
    background: var(--yellow);
    position: absolute;
    top: 0;
    left: 293px;
  }

  .Home_historyBlock__A187O {
    display: grid;
    grid-template-columns: 251px 1fr;
    grid-column-gap: 84px;
    -webkit-column-gap: 84px;
            column-gap: 84px;
    margin-bottom: 110px;
    margin-left: 0;
  }

  .Home_historyBlock__A187O img {
    width: 100%;
    margin-left: auto;
    max-width: unset;
    margin-bottom: 0;
  }

  .Home_historyBlock__A187O:nth-of-type(2) img,
  .Home_historyBlock__A187O:nth-of-type(4) img {
    width: 160px;
    margin-left: auto;
  }

  .Home_historyTitle__3w7XO {
    font-family: Jost;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 100%;
    margin-bottom: 24px;
    position: relative;
    -webkit-transform: unset;
            transform: unset;
    text-align: left;
  }

  .Home_fopBlock__2g1-E {
    position: unset;
  }

  .Home_fopBlock__2g1-E h2 {
    color: var(--white);
    position: absolute;
    top: 100px;
  }

  .Home_fopParkLongImg__SJdDQ {
    display: block;
    width: 100%;
    height: 495px;
    object-fit: cover;
  }

  .Home_fopParkShortImg__1h70v {
    display: none;
  }

  .Home_fopMap__2ArkX {
    position: relative;
    display: block;
    margin: 0 auto;
    width: 300px;
  }

  .Home_fopMap__2ArkX img {
    display: block;
    width: 300px;
    margin: 0 auto;
  }

  .Home_fopPointer__1sKdL {
    display: -webkit-flex;
    display: flex;

    font-family: Jost;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    color: var(--black);

    position: absolute;
    top: 184px;
    left: 191px;
  }

  .Home_fopPointer__1sKdL > div {
    width: 20px;
    height: 20px;
    border: 6px solid var(--yellow);
    border-radius: 50%;
    margin-right: 10px;
  }

  .Home_fopAdvs__Cm_PE {
    margin: 110px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 32px;
    -webkit-column-gap: 32px;
            column-gap: 32px;

    font-family: Jost;
    font-size: 16px;
    color: var(--black);
    text-align: center;
  }

  .Home_fopAdvs__Cm_PE > div {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
  }

  .Home_fopAdvs__Cm_PE img {
    width: 80px;
    margin-bottom: 40px;
  }

  .Home_twoColImg__KMtqP {
    display: block;
    margin: auto;
    width: -webkit-max-content;
    width: max-content;
    max-width: 318px;
  }

  .Home_team__14mkG {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: auto;
    grid-gap: 60px 110px;
    gap: 60px 110px;
  }

  .Home_lead__eZVMT {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    width: 100%;
    margin: 0;
  }

  .Home_lead__eZVMT img {
    width: 190px;
    margin-bottom: 20px;
  }

  .Home_leadName__20DlF {
    font-family: Jost;
    font-weight: 600;
    color: var(--black);
    font-size: 18px;
  }

  .Home_leadPosition__3jGIb {
    margin-top: 6px;
    font-family: Tenor Sans;
    font-size: 14px;
    color: var(--black40);
    text-align: right;
    line-height: 150%;
  }

  .Home_galleryBlock__1LX42,
  .Home_artistsBlock__7Gc2d {
    margin-bottom: 110px;
  }

  .Home_galleryBlockHeader__110Cf {
    width: 1024px;
    padding: 0 24px;
    margin: 0 auto;
    font-family: Jost;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 100%;
    color: var(--black);
    margin-bottom: 40px;
  }

  .Home_photos__3zrAg {
    overflow-x: scroll;
    width: 100%;
    display: -webkit-flex;
    display: flex;
  }

  .Home_photo__1xAKH {
    width: 50vmin;
    max-width: 600px;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    transition: 0.1s;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100%;
    margin-right: 2px;
    /* filter: grayscale(1); */
    z-index: 3;
  }

  .Home_accBtn__k2p8r {
    outline: none;
    font-family: Jost;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    color: var(--black);
    padding: 18px 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .Home_arrow__3dU9n {
    width: 20px;
    height: 20px;
    background: url(/static/media/arrow.7ab53e0c.svg) no-repeat center;
    background-size: 50%;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }

  .Home_arrowUp__3R2YK {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }

  .Home_accHead__ucq2j {
    background: var(--yellow);
  }

  .Home_accPanel___9fo2 {
    padding: 40px;
    font-family: Jost;
    font-size: 18px;
    color: var(--black);
    background: var(--black02);
  }

  .Home_cell__1U4Pq {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
            column-gap: 40px;
    padding: 8px 0;
  }

  .Home_cell__1U4Pq span:first-child {
    text-align: right;
  }

  .Home_salary__GmUot {
    font-size: 18px;
    margin: 0 0 40px;
    font-weight: 600;
  }

  .Home_panelText__2QU-e {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-family: Jost;
    font-weight: normal;
    font-size: 18px;
    line-height: 130%;
    color: var(--black90);
    list-style: none;
  }

  .Home_panelText__2QU-e span,
  .Home_panelText__2QU-e li {
    margin-bottom: 18px;
  }

  .Home_panelText__2QU-e > div {
    font-family: Jost;
    font-weight: 500;
    font-size: 18px;
    color: var(--black);
    margin: 30px auto;
  }

  .Home_smallText__2eCi2 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: var(--black40);
    line-height: 130%;
    margin-bottom: 0 !important;
  }

  .Home_disclaimer__3L8Lf {
    width: 690px;
    margin: 110px auto;
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
  }
}

.alice-carousel .animated {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.alice-carousel .animated-out {
  z-index: 1;
}

.alice-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.alice-carousel {
  position: relative;
  display: inline-block;
  width: 95vmin;
  margin: auto;
  direction: ltr;
}

.alice-carousel__wrapper {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
  width: 100%;
  height: auto;
}

.alice-carousel__stage {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  transform-style: flat;
  -webkit-transform-style: flat;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.alice-carousel__stage-item {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  vertical-align: top;
  white-space: normal;
  line-height: 0;
}
.alice-carousel__stage-item * {
  line-height: normal;
  line-height: initial;
}
.alice-carousel__stage-item.__hidden {
  opacity: 0;
  overflow: hidden;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  display: inline-block;
  box-sizing: border-box;
  width: 40px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.alice-carousel__prev-btn [data-area]::after,
.alice-carousel__next-btn [data-area]::after {
  position: relative;
  content: '';
  display: block;
  content: '';
  width: 14px;
  height: 14px;
  border-left: 3px solid var(--white);
  border-bottom: 3px solid var(--white);
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  position: absolute;
  top: 50%;
  left: 8px;
  opacity: 0.4;
}

.alice-carousel__next-btn [data-area]::after {
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
  left: unset;
  right: 8px;
}

.alice-carousel__prev-btn {
  text-align: right;
  right: unset;
  left: 0;
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  display: block;
  cursor: pointer;
  color: var(--white);
  width: 100%;
  height: 100%;
}
.alice-carousel__prev-btn-item:hover,
.alice-carousel__next-btn-item:hover {
  color: darkred;
}
.alice-carousel__prev-btn-item.__inactive,
.alice-carousel__next-btn-item.__inactive {
  opacity: 0.4;
  pointer-events: none;
}

.alice-carousel__play-btn {
  position: absolute;
  top: 30px;
  left: 20px;
  display: inline-block;
}
.alice-carousel__play-btn:hover {
  cursor: pointer;
}
.alice-carousel__play-btn-wrapper {
  position: relative;
  width: 32px;
  height: 32px;
  padding: 10px;
  border-radius: 50%;
  background-color: #fff;
}

.alice-carousel__play-btn-item {
  position: absolute;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border: 0;
  outline: none;
  background: transparent;
}
.alice-carousel__play-btn-item::before,
.alice-carousel__play-btn-item::after {
  position: absolute;
  pointer-events: none;
  display: block;
  width: 0;
  height: 0;
  content: '';
  transition: all 0.4s linear;
  border-width: 8px 0 8px 15px;
  border-style: solid;
  border-color: transparent;
  border-left-color: #465798;
}
.alice-carousel__play-btn-item::before {
  left: 5px;
  height: 14px;
}
.alice-carousel__play-btn-item::after {
  top: 7px;
  left: 18px;
}
.alice-carousel__play-btn-item.__pause::before,
.alice-carousel__play-btn-item.__pause::after {
  height: 30px;
  border-width: 0 0 0 10px;
}
.alice-carousel__play-btn-item.__pause::after {
  top: 0;
  left: 18px;
}

.alice-carousel__dots {
  margin: 30px 3px 5px;
  padding: 0;
  list-style: none;
  text-align: center;
}
.alice-carousel__dots > li {
  display: inline-block;
}
.alice-carousel__dots-item:not(.__custom) {
  width: 8px;
  height: 8px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #e0e4fb;
}
.alice-carousel__dots-item:not(.__custom):not(:last-child) {
  margin-right: 20px;
}
.alice-carousel__dots-item:not(.__custom):hover,
.alice-carousel__dots-item:not(.__custom).__active {
  background-color: #6e7ebc;
}

.alice-carousel__slide-info {
  position: absolute;
  top: 20px;
  right: 20px;
  display: inline-block;
  padding: 5px 10px;
  color: #465798;
  border-radius: 5px;
  background-color: rgba(224, 228, 251, 0.6);
}
.alice-carousel__slide-info-item {
  vertical-align: middle;
  line-height: 0;
}

.alice-carousel__next-btn-wrapper,
.alice-carousel__prev-btn-wrapper {
  width: 100%;
  height: 100%;
}

.ImageGallery_container__23pXz {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--black);
  z-index: 10;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  visibility: visible;
}

.ImageGallery_image__Qhog8 {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
}

.ImageGallery_close__2Taaj {
  position: absolute;
  top: 40px;
  right: 40px;
  font-family: Tenor Sans;
  font-size: 16px;
  line-height: 19px;
  color: var(--white);
  text-transform: uppercase;
}

.ImageGallery_hide__J55gh {
  visibility: hidden;
  height: 0;
}
.Gallery_container__2Jd3V {
  width: 100%;
  padding-top: 120px;
}

.Gallery_header__1gVDq {
  font-family: Jost;
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 100%;
  color: var(--black);
  text-align: left;
  margin: 0 26px 63px;
}

.Gallery_scrollContainer__1_IGX {
  width: 100%;
  position: relative;
  margin-bottom: 100px;
}

.Gallery_videos__1yDxV {
  overflow-x: scroll;
  width: 100%;
  display: -webkit-flex;
  display: flex;
}

.Gallery_video__3A438 {
  width: 95vmin;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  transition: 0.1s;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  margin-right: 2px;
  z-index: 3;
}

.Gallery_player__1G3l2 {
  width: 100%;
  height: calc(95vmin * 0.7);
  transition: 0.2s;
}

.Gallery_title__1mkks {
  font-family: Jost;
  font-weight: bold;
  font-size: 14px;
  line-height: 130%;
  color: var(--black);
  margin-bottom: 14px;
  margin-top: 10px;
}

.Gallery_photos__3VP2C {
  height: calc(190vmin * 0.7);
  max-height: 90vh;
  display: grid;
  grid-auto-columns: calc(70vh / 2 / 7 * 10);
  grid-template-rows: repeat(2, 50%);
  grid-auto-columns: 95vmin;
  grid-auto-flow: column;
  overflow-x: scroll;
  overflow-y: hidden;
  grid-gap: 2px;
  gap: 2px;
}

.Gallery_photo__3v5Th {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-filter: brightness(1.1) grayscale(1);
          filter: brightness(1.1) grayscale(1);
  transition: 0.3s;
}

.Gallery_photo__3v5Th:hover {
  transition: 0.3s;
  -webkit-filter: brightness(1.1)  grayscale(0);
          filter: brightness(1.1)  grayscale(0);
}

.Gallery_titleLine__NFd1k {
  width: 100%;
  overflow: hidden;
  margin: 2px 0;
  background: var(--black);
  color: var(--white);

  font-family: Jost;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 130%;
  text-transform: uppercase;
  padding: 2px;
}

.Gallery_titleLine__NFd1k span {
  margin: 0 100px;
}

@media (min-width: 768px) {
  .Gallery_scrollContainer__1_IGX {
    margin-bottom: 160px;
  }

  .Gallery_photos__3VP2C {
    height: calc(180vmin * 0.7);
    max-height: 70vh;
    grid-auto-columns: calc(70vh / 2 / 7 * 10);
  }

  .Gallery_photo__3v5Th {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
  }

  .Gallery_video__3A438 {
    /* width: 80vmin; */
    width: calc(70vh / 2 / 7 * 10);
    height: calc(90vmin * 0.7 / 2);
  }

  .Gallery_player__1G3l2 {
    height: calc(90vmin * 0.7 / 2);
  }
}

@media (min-width: 1024px) {
  .Gallery_header__1gVDq {
    font-size: 56px;
  }
}

.Input_container__zo7M0 {
  margin: 20px 0 0;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Input_textarea__h_Kof,
.Input_input__3PNbO {
  outline: none;
  background: none;
  border: none;
  border-bottom: 1px solid var(--black05);
  margin: 2px 0 2px;
  padding: 3px 0;

  font-family: Tenor Sans;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: -0.03em;
  border-radius: 0;
}
.Input_textarea__h_Kof::-webkit-input-placeholder, .Input_input__3PNbO::-webkit-input-placeholder {
  color: var(--black20);
}
.Input_textarea__h_Kof:-ms-input-placeholder, .Input_input__3PNbO:-ms-input-placeholder {
  color: var(--black20);
}
.Input_textarea__h_Kof::placeholder,
.Input_input__3PNbO::placeholder {
  color: var(--black20);
}

.Input_textarea__h_Kof {
  min-height: 120px;
}

.Input_uppercase__RZuOf {
  text-transform: uppercase;
}

.Input_lowercase__KLgBE {
  text-transform: lowercase;
}

.Input_error__2VO4D,
.Input_label__z-LP7 {
  font-family: Jost;
  font-weight: normal;
  font-size: 16px;
  color: var(--black50);
  width: -webkit-max-content;
  width: max-content;
  word-break: break-word;
  width: 100%;
}

.Input_error__2VO4D {
  display: block;
  min-height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: var(--red);
  visibility: visible;
}

.Input_noError__3VmA4 {
  visibility: hidden;
}

.Input_inputError__2sBAX,
.Input_labelError__K5IF- {
  color: var(--red);
  border-color: var(--red);
}

@media(min-width: 1024px) {
  .Input_container__zo7M0 {
    margin: 30px 0 0;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  
  .Input_input__3PNbO {
    outline: none;
    background: none;
    border: none;
    border-bottom: 1px solid var(--black05);
    height: 32px;
    margin: 2px 0 6px;
    padding: 3px 0;
  
    font-family: Tenor Sans;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.03em;
  }
  
  .Input_input__3PNbO::-webkit-input-placeholder {
    color: var(--black20);
  }
  
  .Input_input__3PNbO:-ms-input-placeholder {
    color: var(--black20);
  }
  
  .Input_input__3PNbO::placeholder {
    color: var(--black20);
  }
  
  .Input_error__2VO4D,
  .Input_label__z-LP7 {
    font-family: Jost;
    font-weight: normal;
    font-size: 16px;
    text-align: justify;
    color: var(--black50);
    width: -webkit-max-content;
    width: max-content;
  }
  
  .Input_error__2VO4D {
    display: block;
    min-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: var(--red);
    visibility: visible;
  }
  
  .Input_noError__3VmA4 {
    visibility: hidden;
  }
  
  .Input_inputError__2sBAX,
  .Input_labelError__K5IF- {
    color: var(--red);
    border-color: var(--red);
  }
  
}
.Select_container__1YD_G {
  margin: 20px 0 0;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: relative;

  font-family: Tenor Sans;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: -0.03em;
}

.Select_selectBtn__2I98E {
  border-bottom: 1px solid var(--black05);
  height: 32px;
  margin: 2px 0 6px;
  padding: 3px 0;

  font-family: Tenor Sans;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: -0.03em;
  border-radius: 0;

  text-align: left;
  position: relative;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.Select_selectBtn__2I98E span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
}

.Select_selectBtn__2I98E::after {
  content: '';
  display: block;
  width: 10px;
  height: 12px;
  position: absolute;
  top: 10px;
  right: 10px;
  background: url(/static/media/arrow.7ab53e0c.svg) no-repeat;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  transition: 0.3s;
}

.Select_selectBtnOpen__dPe2r::after {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  transition: 0.3s;
}

.Select_error__11cvO,
.Select_label__3tAIC {
  font-family: Jost;
  font-weight: normal;
  font-size: 16px;
  text-align: justify;
  color: var(--black50);
  width: -webkit-max-content;
  width: max-content;
}

.Select_error__11cvO {
  display: block;
  min-height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: var(--red);
  visibility: visible;
}

.Select_noError__1Bp8G {
  visibility: hidden;
}

.Select_selectError__1vyTg .Select_label__3tAIC,
.Select_selectError__1vyTg .Select_selectBtn__2I98E {
  color: var(--red);
  border-color: var(--red);
}

/* select */

.Select_list__3Y7tW {
  position: absolute;
  top: 42px;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.14);
  list-style: none;
  overflow-y: scroll;
  max-height: 400px;
  visibility: visible;
  outline: none;
}

.Select_listHide__2GYRK {
  visibility: hidden;
}

.Select_listItem__2dagq {
  font-size: 16px;
  color: var(--black);
  padding: 12px 18px;
  background: #fff;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .Select_container__1YD_G {
    margin: 30px 0 0;
  }

  .Select_error__11cvO,
  .Select_label__3tAIC {
    font-family: Jost;
    font-weight: normal;
    font-size: 16px;
    text-align: justify;
    color: var(--black30);
    width: -webkit-max-content;
    width: max-content;
  }

  .Select_error__11cvO {
    display: block;
    min-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: var(--red);
    visibility: visible;
  }
}

.ModalPassport_container__ink6R {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 900px;
  min-height: 200px;
  padding: 20px;
  background: var(--yellow);
  outline: none;
  position: absolute;
}

.ModalPassport_closeBtn__3XW61 {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  background: url(/static/media/removeXblack.45b9c0f0.svg) no-repeat bottom center;
  background-size: 85%;
}

.ModalPassport_openBtn__2LHHq {
  font-family: Jost;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: var(--black);
  background: var(--yellow);
  padding: 2px 16px;
  margin-left: auto;
  border-radius: 50px;
}

.ModalPassport_container__ink6R h2 {
  font-family: Jost;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  color: var(--black);
  margin: 0 0 26px;
}

.ModalPassport_btns__2OAAs {
  margin: 12px 0 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.ModalPassport_desc__1P4GJ {
  line-height: 150%;
  font-size: 14px;
}

.ModalPassport_btnOutline__1VP3d,
.ModalPassport_btn__G22QE {
  font-family: Jost;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 40px;
  background: var(--black);
  color: var(--yellow);
  margin: 14px 0 0 0;
  width: 100%;
}

.ModalPassport_btnOutline__1VP3d {
  background: var(--yellow);
  border: 2px solid var(--black);
  color: var(--black);
}

@media (min-width: 1024px) {
  .ModalPassport_container__ink6R {
    padding: 40px;
  }

  .ModalPassport_closeBtn__3XW61 {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 30px;
    height: 30px;
    background: url(/static/media/removeXblack.45b9c0f0.svg) no-repeat top center;
    background-size: 55%;
  }

  .ModalPassport_container__ink6R h2 {
    font-family: Jost;
    font-weight: 600;
    font-size: 22px;
    line-height: 130%;
    color: var(--black);
    margin: 0 0 40px;
  }

  .ModalPassport_btns__2OAAs {
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .ModalPassport_desc__1P4GJ {
    font-size: 16px;
  }

  .ModalPassport_btns__2OAAs {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    margin: 20px 0 0 0;
  }

  .ModalPassport_btnOutline__1VP3d,
  .ModalPassport_btn__G22QE {
    margin: 0 0 0 20px;
    max-width: 200px;
  }
}

.PhotoUpload_container__1KXAn {
  margin: 20px 0 0;
  display: grid;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.PhotoUpload_frames__2FUU7 {
  display: -webkit-flex;
  display: flex;
}

.PhotoUpload_btn__26819 {
  width: 158px;
  height: 203px;
  font-family: Jost;
  font-size: 18px;
  font-weight: 600;
  color: var(--black40);
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  border-radius: 3px;
  background: url(/static/media/download.c63ff991.svg) no-repeat bottom 90px center;
  background-size: 30%;
  background-color: var(--black05);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 60px 0;
}

.PhotoUpload_btn__26819:hover span {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.PhotoUpload_imgContainer__3mhtJ {
  width: 158px;
  height: 203px;
  border-radius: 3px;
  border: 1px solid var(--black10);
  overflow: hidden;
  position: relative;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

.PhotoUpload_imgContainer__3mhtJ img {
  width: 158px;
  height: 203px;
  object-fit: cover;
  background-color: var(--black20);
}

.PhotoUpload_btnRemove__3u94M {
  padding: 4px;
  width: 30px;
  height: 30px;
  color: var(--red);
  font-size: 0;
  background: url(/static/media/removeX.6906dbe8.svg) no-repeat center center;
  position: absolute;
  right: 2px;
  top: 2px;
  background-size: 60%;
}

.PhotoUpload_imageFrame__3yKWf {
  width: 158px;
  height: 203px;
  margin-left: 20px;
  background: var(--white);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  border-radius: 3px;
  border: 1px solid var(--black10);
  position: relative;
  overflow: hidden;
}

.PhotoUpload_imageFrame__3yKWf img {
  width: 100%;
  height: 100%;
}

.PhotoUpload_imageFrame__3yKWf span {
  position: absolute;
  bottom: 0px;
  font-family: Jost;
  font-size: 12px;
  font-weight: 700;
  display: block;
  background: var(--black40);
  width: 100%;
  text-align: center;
  color: var(--white);
  padding: 4px 0;
}

.PhotoUpload_info__232sk {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-family: Jost;
  color: var(--black50);
  font-size: 14px;
}

.PhotoUpload_infoText__1Hr7X {
  font-size: 15px;
}

.PhotoUpload_infoText__1Hr7X a {
  color: var(--black);
  font-weight: 500;
  text-decoration: underline;
}

.PhotoUpload_errorMobile__4Kclx,
.PhotoUpload_errorDesktop__CIQGW {
  font-family: Jost;
  font-weight: normal;
  font-size: 16px;
  color: var(--black50);
  font-size: 12px;
  font-weight: 400;
  color: var(--red);
  visibility: visible;
  
  width: 100%;
  min-height: 36px;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
}

.PhotoUpload_errorDesktop__CIQGW {
  display: none;
}

.PhotoUpload_btnError__3we6i {
  color: var(--red);
  border: 1px solid var(--red);
}

@media (min-width: 1024px) {
  .PhotoUpload_container__1KXAn {
    margin: 30px 0 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .PhotoUpload_errorDesktop__CIQGW,
  .PhotoUpload_label__26B8Y {
    font-family: Jost;
    font-weight: normal;
    font-size: 16px;
    text-align: justify;
    color: var(--black30);
    width: -webkit-max-content;
    width: max-content;
  }

  .PhotoUpload_errorDesktop__CIQGW {
    display: -webkit-inline-flex;
    display: inline-flex;
    font-size: 14px;
    font-weight: 400;
    color: var(--red);
    visibility: visible;
  }

  .PhotoUpload_errorMobile__4Kclx {
    display: none;
  }

  .PhotoUpload_imageFrame__3yKWf {
    margin: 0 30px;
  }

  .PhotoUpload_imageFrame__3yKWf span {
    font-size: 14px;
  }
}

.Profile_main__YfNHw {
  width: 100%;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 120px;
}

.Profile_head__3C9BQ {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  max-width: 550px;
  margin-bottom: 36px;
}

.Profile_header__2ot0f {
  font-family: Jost;
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 100%;
  color: var(--black);
  text-align: left;
}

.Profile_desc__2MRSi {
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: var(--black);
  margin-bottom: 60px;
  padding: 0 20px;
  max-width: 550px;
}

.Profile_desc__2MRSi span {
  display: block;
  margin-bottom: 8px;
}

.Profile_desc__2MRSi span b {
  background: var(--yellow);
}

.Profile_desc__2MRSi a {
  display: -webkit-inline-flex;
  display: inline-flex;
  background: var(--black05);
  padding: 0 8px;
  color: var(--black);
  font-family: Jost;
  font-weight: 500;
}

.Profile_form__337o4 {
  width: 100%;
  background: var(--white);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  max-width: 550px;
  margin: 0 auto;
  padding: 12px 0 0;
}

.Profile_inputsTwoOne__2Y2Rg,
.Profile_inputs__3lZx4 {
  width: 100%;
  display: block;
}

.Profile_field__fvG-u {
  padding: 26px;
  width: 100%;
}

.Profile_fieldName__qR7hN {
  font-family: Jost;
  font-weight: bold;
  font-size: 20px;
  line-height: 130%;
  color: var(--black);
  margin-bottom: 16px;
}

.Profile_info__Mh4kN {
  display: block;
  margin: 0px 0 30px;
  font-family: Jost;
  color: var(--black30);
  font-size: 14px;
}

.Profile_passportHeader__2Lm0M {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline;
}

.Profile_disclaimer__KwQNS {
  font-family: Tenor Sans;
  font-size: 10px;
  line-height: 150%;
  color: var(--black40);
  margin: 0 auto;
  text-align: center;
}

.Profile_submitWrapper__32M6B {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding: 40px;
  margin-bottom: 10vh;
}

.Profile_actionBtn__2teQ_ {
  font-family: Jost;
  font-weight: 600;
  font-size: 16px;
  padding: 12px 26px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  background: var(--yellow);
  color: var(--black);
  height: -webkit-max-content;
  height: max-content;
  margin: 0 auto 40px;
  position: relative;
}

.Profile_loader__15gtx {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  overflow: hidden;
}

.Profile_loader__15gtx div {
  width: 100%;
  height: 100%;
  background: var(--black);
  -webkit-animation: Profile_loader__15gtx 2s infinite;
          animation: Profile_loader__15gtx 2s infinite;
}

.Profile_subscription__2nmtW {
  display: -webkit-flex;
  display: flex;
  color: var(--black);
  font-family: Tenor Sans;
  font-size: 14px;
  line-height: 150%;
}

.Profile_checkbox__OQEJx {
  width: 24px;
  height: 16px;
  border-bottom: 4px solid var(--yellow);
  border-left: 4px solid var(--yellow);
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  margin-right: 16px;
}

.Profile_checkboxHide__3p4Au {
  opacity: 0;
}

.Profile_checkboxTextLine__kShap {
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
}

.Profile_temp__3wdtF {
  display: none;
  width: 140px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.Profile_temp__3wdtF button {
  color: var(--black20);
}

.Profile_disabled__PeBg4 {
  color: var(--black20);
}

.Profile_addPassport__3tehS {
  font-family: Jost;
  font-weight: 600;
  font-size: 16px;
  padding: 12px 36px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  background: var(--yellow);
  color: var(--black);
  margin-left: 20px;
  height: -webkit-max-content;
  height: max-content;
}

.Profile_gray__263-L {
  background: var(--black03);
  padding: 12px 0;
}

@media (min-width: 1024px) {
  .Profile_main__YfNHw {
    padding-top: 160px;
  }

  .Profile_head__3C9BQ {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    max-width: 1024px;
    padding: 0 24px;
    margin: 0 auto;
    margin-bottom: 63px;
  }

  .Profile_header__2ot0f {
    font-size: 56px;
    text-align: left;
    padding: 0;
  }

  .Profile_desc__2MRSi {
    font-size: 16px;
    width: 1000px;
    max-width: 100%;
    margin: 0 auto 60px;
    padding: 0;
    max-width: 1024px;
    padding: 0 24px;
  }

  .Profile_form__337o4 {
    max-width: 100%;
    width: 1000px;
    margin: 0 auto;
    max-width: 976px;
    padding: 26px 0 0;
  }

  .Profile_field__fvG-u {
    padding: 32px 52px;
  }

  .Profile_fieldName__qR7hN {
    margin-bottom: 10px;
  }

  .Profile_disclaimer__KwQNS {
    width: 300px;
  }

  .Profile_submitWrapper__32M6B {
    margin: 0 0 40px;
  }

  .Profile_submit__2U-Pw {
    width: 279px;
    height: 54px;
  }

  .Profile_inputs__3lZx4 {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
            column-gap: 40px;
  }

  .Profile_inputsTwoOne__2Y2Rg {
    display: grid;
    grid-template-columns: calc(60% + 40px) 30%;
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
            column-gap: 40px;
  }

  .Profile_actionBtn__2teQ_ {
    padding: 12px 36px;
  }

  .Profile_gray__263-L {
    padding: 26px 0;
  }

  .Profile_info__Mh4kN {
    margin: 0 0 10px;
  }
}

@-webkit-keyframes Profile_loader__15gtx {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  50% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes Profile_loader__15gtx {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  50% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.ProfilePassport_main__3p6GB {
  width: 100%;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 120px;
}

.ProfilePassport_head__yTf3i {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  max-width: 550px;
  margin-bottom: 36px;
}

.ProfilePassport_header__1joaJ {
  font-family: Jost;
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 100%;
  color: var(--black);
  text-align: left;
}

.ProfilePassport_desc__-u5E1 a {
  display: -webkit-inline-flex;
  display: inline-flex;
  background: var(--black05);
  padding: 0 8px;
  color: var(--black);
  font-family: Jost;
  font-weight: 500;
}

.ProfilePassport_desc__-u5E1 {
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: var(--black);
  margin-bottom: 60px;
  padding: 0 20px;
  max-width: 550px;
}

.ProfilePassport_form__32mbE {
  width: 100%;
  background: var(--white);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  padding: 26px;
  max-width: 550px;
  margin: 0 auto;
}

.ProfilePassport_inputs__1RCoC {
  display: grid;
  grid-auto-flow: row;
}

.ProfilePassport_field__30Pvd {
  margin-bottom: 46px;
}

.ProfilePassport_fieldName__6G-rk {
  font-family: Jost;
  font-weight: bold;
  font-size: 20px;
  line-height: 130%;
  color: var(--black);
  margin-bottom: 16px;
}

.ProfilePassport_passportHeader__1HJkO {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline;
}

.ProfilePassport_disclaimer__2L3yO {
  font-family: Tenor Sans;
  font-size: 10px;
  line-height: 150%;
  color: var(--black40);
  margin: 20px auto 0;
  text-align: center;
}

.ProfilePassport_submitWrapper__33bW6 {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding: 40px;
  margin-bottom: 10vh;
}

.ProfilePassport_subscription__1nBRa {
  display: -webkit-flex;
  display: flex;
  color: var(--black);
  font-family: Tenor Sans;
  font-size: 14px;
  line-height: 150%;
}

.ProfilePassport_checkbox__26Ujk {
  width: 24px;
  height: 16px;
  border-bottom: 4px solid var(--yellow);
  border-left: 4px solid var(--yellow);
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  margin-right: 16px;
}

.ProfilePassport_checkboxHide__3OK7b {
  opacity: 0;
}

.ProfilePassport_checkboxTextLine__1a0x0 {
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
}

.ProfilePassport_temp__MUxhb {
  display: -webkit-flex;
  display: flex;
  width: 140px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.ProfilePassport_temp__MUxhb button {
  color: var(--black20);
}

.ProfilePassport_disabled__cgcN0 {
  color: var(--black20);
}

.ProfilePassport_actionBtn___Cl7- {
  font-family: Jost;
  font-weight: 600;
  font-size: 16px;
  padding: 12px 26px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  background: var(--yellow);
  color: var(--black);
  margin-left: 20px;
  height: -webkit-max-content;
  height: max-content;
  position: relative;
}

.ProfilePassport_actionBtn___Cl7-:hover {
  transition: 0.2s;
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}

.ProfilePassport_loader__3mWwV {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  overflow: hidden;
}

.ProfilePassport_loader__3mWwV div {
  width: 100%;
  height: 100%;
  background: var(--black);
  -webkit-animation: ProfilePassport_loader__3mWwV 2s infinite;
          animation: ProfilePassport_loader__3mWwV 2s infinite;
}

@media (min-width: 1024px) {
  .ProfilePassport_main__3p6GB {
    padding-top: 160px;
  }

  .ProfilePassport_head__yTf3i {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    max-width: 1024px;
    padding: 0 24px;
    margin: 0 auto;
    margin-bottom: 63px;
  }

  .ProfilePassport_header__1joaJ {
    font-size: 56px;
    text-align: left;
    padding: 0;
  }

  .ProfilePassport_desc__-u5E1 {
    font-size: 16px;
    width: 1000px;
    max-width: 100%;
    margin: 0 auto 60px;
    padding: 0;
    max-width: 1024px;
    padding: 0 24px;
  }

  .ProfilePassport_form__32mbE {
    max-width: 100%;
    padding: 52px;

    width: 1000px;
    margin: 0 auto;
    max-width: 976px;
  }

  .ProfilePassport_field__30Pvd {
    margin-bottom: 66px;
  }

  .ProfilePassport_fieldName__6G-rk {
    margin-bottom: 10px;
  }

  .ProfilePassport_disclaimer__2L3yO {
    width: 600px;
    margin-top: 40px;
  }

  .ProfilePassport_submitWrapper__33bW6 {
    margin: 40px 0;
  }

  .ProfilePassport_inputs__1RCoC {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
            column-gap: 40px;
  }

  .ProfilePassport_actionBtn___Cl7- {
    padding: 12px 36px;
  }
}

@-webkit-keyframes ProfilePassport_loader__3mWwV {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  50% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes ProfilePassport_loader__3mWwV {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  50% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}
.Vacancies_container__1Knf8 {
  width: 100%;
  min-height: 100vh;
  padding-top: 120px;
  max-width: 550px;
  margin: 0 auto;
}

.Vacancies_header__3jgR4 {
  font-family: Jost;
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 100%;
  color: var(--black);
  text-align: left;
  width: 100%;
  max-width: 1024px;
  padding: 0 26px;
  margin: 0 auto;
  margin-bottom: 63px;
}

.Vacancies_posts__1zH89 {
  width: 100%;
  margin: 60px auto 100px;
}

.Vacancies_post__3wpQp {
  position: relative;
  margin: 0 auto 60px;
  padding: 40px 26px;
  width: 100%;
  background: var(--white);
  box-shadow: 0px 22px 94px rgba(0, 0, 0, 0.08);
}

.Vacancies_actionBtnEmpty__2J16z,
.Vacancies_actionBtn__1pcor {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
  text-align: center;

  font-family: Jost;
  font-weight: 600;
  font-size: 16px;
  padding: 16px 26px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  background: var(--yellow);
  color: var(--black);
}

.Vacancies_actionBtnEmpty__2J16z {
  position: relative;
  padding: 12px 26px;
  margin-top: 40px;
  width: -webkit-max-content;
  width: max-content;
}

.Vacancies_subTitle__4ezJD,
.Vacancies_title__SjaJ9 {
  max-width: 900px;
  margin-bottom: 32px;
  position: relative;
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 100%;
  color: #000000;
}

.Vacancies_subTitle__4ezJD {
  font-size: 20px;
  width: 100%;
  font-weight: 500;
  margin-bottom: 18px;
}

.Vacancies_desc__FepUg {
  max-width: 900px;
  font-family: Tenor Sans;
  font-size: 16px;
  line-height: 140%;
  color: var(--black80);
  margin-bottom: 60px;
}

.Vacancies_tags__CEX8D {
  width: 100%;
  max-width: 900px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 30px;
}

.Vacancies_tag__1BmOo {
  word-break: break-word;
  display: block;
  font-family: Jost;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  border: 2px solid var(--yellow);
  color: var(--black);
  padding: 6px 12px;
  background: var(--white);
  margin: 0 12px 12px 0;
}

.Vacancies_emptyState__1JVGw {
  padding: 0 26px;
  margin-top: 100px;
  width: 100%;
  height: 60vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Vacancies_emptyState__1JVGw h2 {
  line-height: 110%;
  font-family: Jost;
  margin-bottom: 20px;
  font-weight: 500;
}

.Vacancies_emptyState__1JVGw .Vacancies_btn__1hjHq {
  position: relative;
  top: unset;
  right: unset;
  margin: 0 20px 20px 0;
}

.Vacancies_emptyState__1JVGw .Vacancies_btn__1hjHq:nth-child(2) {
  border: 2px solid var(--black);
  background: var(--black);
  color: var(--white);
}

@media (min-width: 1024px) {
  .Vacancies_container__1Knf8 {
    padding-top: 160px;
    max-width: unset;
  }

  .Vacancies_header__3jgR4 {
    font-size: 56px;
    text-align: left;
    padding: 0 26px;
  }

  .Vacancies_posts__1zH89 {
    width: 100%;
    margin: 60px auto 0;
    max-width: 1024px;
    padding: 0 26px;
    padding-bottom: 100px ;
  }

  .Vacancies_post__3wpQp {
    padding: 60px;
    min-width: 900px;
    max-width: 1024px;
  }

  .Vacancies_emptyState__1JVGw {
    padding: 0;
  }
}

.Footer_container__st7Zp {
  max-width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  background: var(--yellow);
}

.Footer_wrapper__1UzXs {
  padding: 60px 20px 40px;
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 60px;
  row-gap: 60px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.Footer_header__2Lo8V {
  font-family: Jost;
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 100%;
  color: var(--black);
  text-align: center;
  margin-bottom: 20px;
}

.Footer_contacts__uK5fq {
  margin: 0 auto;
  width: -webkit-max-content;
  width: max-content;
  display: grid;
  grid-auto-flow: row;
  position: relative;
}

.Footer_contact__8nNuz {
  display: -webkit-flex;
  display: flex;
  padding: 10px;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  position: relative;
}

.Footer_label__b_WKh {
  display: block;
  width: 70px;
  text-align: right;
  margin-right: 30px;
  font-family: Tenor Sans;
  font-size: 14px;
}

.Footer_data__1muk1 {
  max-width: 170px;
  font-family: Jost;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  color: var(--black);
}

.Footer_contact__8nNuz a:hover {
  color: var(--white);
}

.Footer_subscrDesk__1JyZO,
.Footer_subscrMobile__2AwIJ {
  position: relative;
  width: 290px;
  height: 54px;
  background: var(--black);

  font-family: Jost;
  font-weight: 800;
  font-size: 19px;
  text-transform: lowercase;
  color: var(--yellow);

  display: -webkit-inline-flex;

  display: inline-flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  padding: 4px;
}

.Footer_subscrDesk__1JyZO {
  display: none;
}
.Footer_subscrMobile__2AwIJ {
  display: -webkit-inline-flex;
  display: inline-flex;
  margin: 0 auto;
}

.Footer_socials__e39nQ {
  margin: 0 auto;
}

.Footer_socialLink__vPZTi {
  font-family: Jost;
  text-transform: uppercase;
  font-size: 14px;
  margin-right: 20px;
}

.Footer_socialLink__vPZTi img {
  width: 40px;
}

.Footer_socialLink__vPZTi:last-child {
  margin-right: 0;
}

.Footer_recaptcha__1Iht3 {
  text-align: center;
  font-family: Jost;
  font-size: 12px;
  font-weight: 400;
  color: var(--black50);
}

.Footer_recaptcha__1Iht3 a {
  color: var(--black50);
  text-decoration: underline;
}

@media (min-width: 768px) {
  .Footer_container__st7Zp {
    height: auto;
    min-height: auto;
    max-height: auto;
  }

  .Footer_header__2Lo8V br:nth-of-type(2) {
    display: none;
    text-align: left;
  }

  .Footer_contacts__uK5fq {
    position: relative;
  }

  .Footer_contact__8nNuz {
    padding: 12px;
  }

  .Footer_label__b_WKh {
    font-size: 16px;
  }

  .Footer_data__1muk1 {
    font-size: 18px;
  }

  .Footer_subscrDesk__1JyZO,
  .Footer_subscrMobile__2AwIJ {
    width: 290px;
    height: 54px;
  }
}

@media (min-width: 1024px) {
  .Footer_container__st7Zp {
    padding: 40px;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .Footer_wrapper__1UzXs {
    grid-row-gap: 80px;
    row-gap: 80px;
  }

  .Footer_header__2Lo8V br {
    display: none;
  }

  .Footer_contacts__uK5fq {
    width: 100%;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: column;
    -webkit-align-items: end;
            align-items: end;
  }

  .Footer_contact__8nNuz a:hover {
    color: var(--yellow);
  }

  .Footer_label__b_WKh {
    font-size: 16px;
  }

  .Footer_data__1muk1 {
    font-size: 18px;
  }

  .Footer_subscrDesk__1JyZO,
  .Footer_subscrMobile__2AwIJ {
    position: absolute;
    top: 10px;
    right: 0;
  }

  .Footer_subscrDesk__1JyZO {
    display: -webkit-inline-flex;
    display: inline-flex;
  }

  .Footer_subscrMobile__2AwIJ {
    display: none;
  }
}

.Menu_container__1JNs2 {
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  z-index: 8;
  background: var(--white70);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  transition: 0.2s;
  visibility: visible;

  padding: 20px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.Menu_hideMenu__3AjgB {
  visibility: hidden;
  opacity: 0;
  transition: 0.2s;
}

.Menu_links__LQVlq {
  margin-top: -12vmin;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100%;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  justify-content: center;
}

.Menu_link__1hJ0p {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: var(--black);
  text-align: center;
  margin-bottom: 20px;
}


@media (min-width: 768px) {
  .Menu_container__1JNs2 {
    padding: 40px;
  }

  .Menu_link__1hJ0p {
    font-size: 22px;
  }
}

.Header_navbar__3zH7f {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0 20px;
  z-index: 9;
  -webkit-align-items: center;
          align-items: center;
  transition: 0.5s;
  background: var(--white70);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.Header_navbar__3zH7f.Header_hideHeader__2Ok23 {
  transition: 0.5s;
  background: transparent;
  -webkit-backdrop-filter: blur(0px);
          backdrop-filter: blur(0px);
}

.Header_navbarOpenMenu__2E6gc {
  background: none;
  -webkit-backdrop-filter: none;
          backdrop-filter: none;
  transition: 0.3s;
}

.Header_logoImg__2dmRS {
  width: 53px;
  padding: 16px 0;
  transition: 0.5s;
}

.Header_hideHeader__2Ok23 .Header_logoImg__2dmRS {
  opacity: 0;
  transition: 0.5s;
}

.Header_links__TCu1V {
  display: -webkit-flex;
  display: flex;
  height: 100%;
}

.Header_link__2wI74 {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: var(--black);
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 16px 30px;
  height: 100%;
  transition: 0.5s;
}

.Header_hideHeader__2Ok23 .Header_link__2wI74 {
  color: var(--white);
  transition: 0.5s;
}

.Header_link__2wI74 span {
  line-height: 200%;
  border-bottom: 4px solid transparent;
}

.Header_linkActive__H63vu span {
  border-bottom: 3px solid var(--yellow);
}

.Header_menuBtn__3NxJT {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 20px 0 20px 20px;
}

.Header_menuBtn__3NxJT div {
  width: 24px;
  height: 4px;
  background: var(--black);
  transition: 0.3s;
}

.Header_hideHeader__2Ok23 .Header_menuBtn__3NxJT div {
  background: var(--white);
}

.Header_shortLine__3_ZFs {
  margin-bottom: 2px;
  -webkit-transform-origin: top right;
          transform-origin: top right;
}

.Header_longLine__2LZgc {
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
}

.Header_menuOpen__Z_s4Q .Header_shortLine__3_ZFs {
  transition: 0.3s;
  -webkit-transform: translateY(-5px) rotate(-45deg);
          transform: translateY(-5px) rotate(-45deg);
}

.Header_menuOpen__Z_s4Q .Header_longLine__2LZgc {
  width: 24px;
  transition: 0.3s;
  -webkit-transform: translateY(5px) rotate(45deg);
          transform: translateY(5px) rotate(45deg);
}

.Header_links__TCu1V {
  display: none;
}

@media (max-height: 375px) {
}

@media (min-width: 768px) {
  .Header_colorZone__3seEl {
    background: var(--yellow);
    padding: 14px 14px 12px;
    margin: 40px;
  }

  .Header_logoImg__2dmRS {
    width: 50px;
  }

  /*   
  .shortLine {
    width: 40px;
    height: 4px;
    margin-bottom: 9px;
  }
  
  .longLine {
    width: 60px;
    height: 4px;
  }
  
  .menuOpen .shortLine {
    transform: translateY(-7px) rotate(-45deg)
  }
  
  .menuOpen .longLine {
    width: 40px;
    transform: translateY(7px) rotate(45deg)
  } */
}

@media (min-width: 1024px) {
  .Header_navbar__3zH7f {
    padding: 0 40px;
  }

  .Header_logoImg__2dmRS {
    width: 70px;
    padding: 16px 0;
  }

  .Header_links__TCu1V {
    display: -webkit-flex;
    display: flex;
  }

  .Header_link__2wI74:first-child {
    display: none;
  }

  .Header_menuBtn__3NxJT {
    display: none;
  }
}

