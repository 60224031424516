.container {
  width: 100%;
  line-height: 150%;
  font-size: 14px;
}

.block {
  width: 100%;
  margin-bottom: 100px;
  position: relative;
}

.blockContainer {
  position: relative;
  width: 100%;
  padding: 0 16px;
  margin: 0 auto;
  margin-bottom: 100px;
  max-width: 550px;
}

.start {
  position: relative;
  margin-bottom: 0;
  height: 100vh;
  min-height: 680px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 100px;
}

.startWrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: var(--white);
  display: none;
}

.startContent {
  background: var(--white);
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 60px 20px 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.startContent p {
  font-family: Jost;
  font-size: 18px;
  font-weight: 400;
  color: var(--black80);
  text-align: center;
  max-width: 500px;
}

.startBackground {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  bottom: 170px;
  right: 0;
}

.logo {
  width: 160px;
  margin: 0 0 50px
}

.blockHeader {
  font-family: Jost;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 100%;
  color: var(--black);
  margin-bottom: 60px;
  text-align: center;
}

.blockHeader a {
  color: inherit;
  text-decoration: underline;
}

.aboutImageDesk {
  display: none;
  width: 384px;
}

.aboutImageMob {
  width: 284px;
  margin: 40px auto;
}

.twoColText {
  display: grid;
  grid-auto-flow: row;
  column-gap: 32px;
}

.twoColImg {
  display: block;
  margin: 60px auto;
  width: max-content;
  max-width: 280px;
  filter: grayscale(1);
}

.reverse {
  display: flex;
  flex-direction: column-reverse;
}

.history {
  position: relative;
}

.historyLine {
  height: 100%;
  width: 6px;
  background: var(--yellow);
  position: absolute;
  top: 0;
  left: 28px;
}

.historyBlock {
  display: grid;
  grid-auto-flow: row;
  margin-left: 60px;
  margin-bottom: 80px;
  position: relative;
}

.historyBlock img {
  max-width: 180px;
  margin-bottom: 20px;
}

.historyTitle {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 100%;
  margin-bottom: 24px;
  min-width: 110px;
  display: block;
  text-align: right;

  position: absolute;
  top: 0;
  transform-origin: top right;
  transform: rotate(270deg) translateY(-175px);
}

.link {
  display: block;
  width: max-content;
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  margin-top: 40px;
  color: var(--black);
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
}

.link:hover {
  color: var(--yellow);
}

.link:hover .linkArrow {
  background: url('../../img/arrowY.svg') no-repeat center;
}

.link:nth-of-type(2) {
  margin-top: 10px;
}

.linkArrow {
  width: 20px;
  height: 20px;
  margin-left: 6px;
  background: url('../../img/arrow.svg') no-repeat center;
  display: block;
}

.fopBlock {
  position: unset;
}

.fopBlock h2 {
  color: var(--white);
  position: absolute;
  top: 60px;
}

.fopParkLongImg {
  display: none;
}

.fopParkShortImg {
  width: 100%;
  height: 360px;
  object-fit: cover;
  margin-bottom: 20px;
}

.fopMap {
  position: relative;
  display: none;
}

.fopMap img {
  display: block;
  width: 300px;
  margin: 0 auto;
}

.fopPointer {
  display: flex;

  font-family: Jost;
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  color: var(--black);

  position: absolute;
  top: 234px;
  left: 274px;
}

.fopPointer > div {
  width: 20px;
  height: 20px;
  border: 6px solid var(--yellow);
  border-radius: 50%;
  margin-right: 10px;
}

.fopAdvs {
  margin: 80px 0;
  display: grid;
  grid-auto-flow: row;
  gap: 80px 32px;

  font-family: Jost;
  font-size: 14px;
  line-height: 140%;
  color: var(--black);
  text-align: center;
}

.fopAdvs > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  margin: 0 auto;
}

.fopAdvs img {
  width: 80px;
  margin-bottom: 20px;
}

.lead {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 60px;
}

.lead img {
  width: 120px;
  margin-bottom: 20px;
}

.leadName {
  font-family: Jost;
  font-weight: 600;
  color: var(--black);
  font-size: 18px;
}

.leadPosition {
  margin-top: 6px;
  font-family: Tenor Sans;
  font-size: 14px;
  color: var(--black40);
  text-align: center;
  line-height: 150%;
}

.galleryBlock,
.artistsBlock {
  margin-bottom: 80px;
}

.accBlock {
  margin-bottom: 110px !important;
  padding: 0;
}

.galleryBlockHeader {
  width: 100%;
  padding: 0 24px;
  margin: 0 auto;
  font-family: Jost;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 100%;
  color: var(--black);
  margin-bottom: 40px;
}

.photos {
  overflow-x: scroll;
  width: 100%;
  display: flex;
}

.photo {
  width: 90vmin;
  max-width: 600px;
  flex-shrink: 0;
  transition: 0.1s;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: 2px;
  filter: brightness(1.1);
  z-index: 3;
}

.accBtn {
  outline: none;
  font-family: Jost;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  color: var(--black);
  padding: 16px;
  display: flex;
  justify-content: space-between;
}

.arrow {
  width: 20px;
  height: 20px;
  background: url('../../img/arrow.svg') no-repeat center;
  background-size: 50%;
  transform: rotate(90deg);
}

.arrowUp {
  transform: rotate(-90deg);
}

.accHead {
  background: var(--yellow);
}

.accPanel {
  padding: 40px 20px;
  font-family: Jost;
  font-size: 16px;
  color: var(--black);
  background: var(--black02);
}

.cell {
  display: grid;
  grid-template-columns: 3fr 4fr;
  column-gap: 20px;
  padding: 8px 0;
}

.cell span:first-child {
  text-align: right;
}

.salary {
  font-size: 14px;
  margin: 20px 0 40px;
  font-weight: 600;
}

.panelText {
  display: flex;
  flex-direction: column;
  font-family: Jost;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
  color: var(--black90);
  list-style: none;
}

.panelText span,
.panelText li {
  margin-bottom: 30px;
}

.panelText > div {
  font-family: Jost;
  font-weight: 500;
  font-size: 18px;
  color: var(--black);
  margin: 30px auto;
}

.smallText {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: var(--black40);
  line-height: 130%;
  margin-bottom: 0 !important;
}

.disclaimer {
  max-width: 690px;
  margin: 80px auto;
  font-family: Jost;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  padding: 16px;
}

.actionBtn {
  font-family: Jost;
  font-weight: 600;
  font-size: 16px;
  padding: 12px 46px;
  flex-shrink: 0;
  background: var(--yellow);
  color: var(--black);
  width: max-content;
  margin-top: 40px;
}

@media (min-width: 768px) {
  .accBtn {
    padding: 16px 40px;
  }

  .accPanel {
    padding: 40px;
  }
}

@media (min-width: 1024px) {
  .container {
    width: 100%;
    line-height: 150%;
    font-size: 16px;
  }

  .block {
    width: 100%;
    margin-bottom: 250px;
    position: relative;
  }

  .blockContainer {
    position: relative;
    width: 1024px;
    padding: 0 24px;
    margin: 0 auto;
    margin-bottom: 250px;
    max-width: unset;
  }

  .start {
    position: relative;
    margin-bottom: 0;
    height: 100vh;
    width: 100%;
    background: var(--yellow);
    overflow: hidden;
    margin-bottom: 250px;
  }

  .startWrap {
    display: block;
    position: absolute;
    top: -20%;
    right: 55%;
    width: 100%;
    height: 300%;
    background: var(--white);
    transform-origin: top right;
    transform: rotate(348deg);
  }

  .startContent {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 40px 40px 40px 8%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 640px;
    background: transparent;
  }

  .startContent p {
    font-family: Jost;
    font-size: 20px;
    font-weight: 400;
    color: var(--black80);
    text-align: left;
  }

  .startBackground {
    width: 65%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    right: 0;
  }

  .logo {
    width: 300px;
    margin: 0 0 60px
  }

  .blockHeader {
    font-family: Jost;
    font-style: normal;
    font-weight: 600;
    font-size: 46px;
    line-height: 100%;
    color: var(--black);
    margin-bottom: 80px;
  }

  .aboutImageDesk {
    display: block;
    width: 384px;
  }

  .aboutImageMob {
    display: none;
  }

  .twoColText {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 32px;
    margin: 54px 0;
  }

  .history {
    position: relative;
  }

  .historyLine {
    height: 100%;
    width: 6px;
    background: var(--yellow);
    position: absolute;
    top: 0;
    left: 293px;
  }

  .historyBlock {
    display: grid;
    grid-template-columns: 251px 1fr;
    column-gap: 84px;
    margin-bottom: 110px;
    margin-left: 0;
  }

  .historyBlock img {
    width: 100%;
    margin-left: auto;
    max-width: unset;
    margin-bottom: 0;
  }

  .historyBlock:nth-of-type(2) img,
  .historyBlock:nth-of-type(4) img {
    width: 160px;
    margin-left: auto;
  }

  .historyTitle {
    font-family: Jost;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 100%;
    margin-bottom: 24px;
    position: relative;
    transform: unset;
    text-align: left;
  }

  .fopBlock {
    position: unset;
  }

  .fopBlock h2 {
    color: var(--white);
    position: absolute;
    top: 100px;
  }

  .fopParkLongImg {
    display: block;
    width: 100%;
    height: 495px;
    object-fit: cover;
  }

  .fopParkShortImg {
    display: none;
  }

  .fopMap {
    position: relative;
    display: block;
    margin: 0 auto;
    width: 300px;
  }

  .fopMap img {
    display: block;
    width: 300px;
    margin: 0 auto;
  }

  .fopPointer {
    display: flex;

    font-family: Jost;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    color: var(--black);

    position: absolute;
    top: 184px;
    left: 191px;
  }

  .fopPointer > div {
    width: 20px;
    height: 20px;
    border: 6px solid var(--yellow);
    border-radius: 50%;
    margin-right: 10px;
  }

  .fopAdvs {
    margin: 110px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 32px;

    font-family: Jost;
    font-size: 16px;
    color: var(--black);
    text-align: center;
  }

  .fopAdvs > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .fopAdvs img {
    width: 80px;
    margin-bottom: 40px;
  }

  .twoColImg {
    display: block;
    margin: auto;
    width: max-content;
    max-width: 318px;
  }

  .team {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: auto;
    gap: 60px 110px;
  }

  .lead {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    margin: 0;
  }

  .lead img {
    width: 190px;
    margin-bottom: 20px;
  }

  .leadName {
    font-family: Jost;
    font-weight: 600;
    color: var(--black);
    font-size: 18px;
  }

  .leadPosition {
    margin-top: 6px;
    font-family: Tenor Sans;
    font-size: 14px;
    color: var(--black40);
    text-align: right;
    line-height: 150%;
  }

  .galleryBlock,
  .artistsBlock {
    margin-bottom: 110px;
  }

  .galleryBlockHeader {
    width: 1024px;
    padding: 0 24px;
    margin: 0 auto;
    font-family: Jost;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 100%;
    color: var(--black);
    margin-bottom: 40px;
  }

  .photos {
    overflow-x: scroll;
    width: 100%;
    display: flex;
  }

  .photo {
    width: 50vmin;
    max-width: 600px;
    flex-shrink: 0;
    transition: 0.1s;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-right: 2px;
    /* filter: grayscale(1); */
    z-index: 3;
  }

  .accBtn {
    outline: none;
    font-family: Jost;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    color: var(--black);
    padding: 18px 30px;
    display: flex;
    justify-content: space-between;
  }

  .arrow {
    width: 20px;
    height: 20px;
    background: url('../../img/arrow.svg') no-repeat center;
    background-size: 50%;
    transform: rotate(90deg);
  }

  .arrowUp {
    transform: rotate(-90deg);
  }

  .accHead {
    background: var(--yellow);
  }

  .accPanel {
    padding: 40px;
    font-family: Jost;
    font-size: 18px;
    color: var(--black);
    background: var(--black02);
  }

  .cell {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    padding: 8px 0;
  }

  .cell span:first-child {
    text-align: right;
  }

  .salary {
    font-size: 18px;
    margin: 0 0 40px;
    font-weight: 600;
  }

  .panelText {
    display: flex;
    flex-direction: column;
    font-family: Jost;
    font-weight: normal;
    font-size: 18px;
    line-height: 130%;
    color: var(--black90);
    list-style: none;
  }

  .panelText span,
  .panelText li {
    margin-bottom: 18px;
  }

  .panelText > div {
    font-family: Jost;
    font-weight: 500;
    font-size: 18px;
    color: var(--black);
    margin: 30px auto;
  }

  .smallText {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: var(--black40);
    line-height: 130%;
    margin-bottom: 0 !important;
  }

  .disclaimer {
    width: 690px;
    margin: 110px auto;
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
  }
}
