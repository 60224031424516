.container {
  margin: 20px 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  font-family: Tenor Sans;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: -0.03em;
}

.selectBtn {
  border-bottom: 1px solid var(--black05);
  height: 32px;
  margin: 2px 0 6px;
  padding: 3px 0;

  font-family: Tenor Sans;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: -0.03em;
  border-radius: 0;

  text-align: left;
  position: relative;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.selectBtn span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
}

.selectBtn::after {
  content: '';
  display: block;
  width: 10px;
  height: 12px;
  position: absolute;
  top: 10px;
  right: 10px;
  background: url('../../img/arrow.svg') no-repeat;
  transform: rotate(90deg);
  transition: 0.3s;
}

.selectBtnOpen::after {
  transform: rotate(-90deg);
  transition: 0.3s;
}

.error,
.label {
  font-family: Jost;
  font-weight: normal;
  font-size: 16px;
  text-align: justify;
  color: var(--black50);
  width: max-content;
}

.error {
  display: block;
  min-height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: var(--red);
  visibility: visible;
}

.noError {
  visibility: hidden;
}

.selectError .label,
.selectError .selectBtn {
  color: var(--red);
  border-color: var(--red);
}

/* select */

.list {
  position: absolute;
  top: 42px;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.14);
  list-style: none;
  overflow-y: scroll;
  max-height: 400px;
  visibility: visible;
  outline: none;
}

.listHide {
  visibility: hidden;
}

.listItem {
  font-size: 16px;
  color: var(--black);
  padding: 12px 18px;
  background: #fff;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .container {
    margin: 30px 0 0;
  }

  .error,
  .label {
    font-family: Jost;
    font-weight: normal;
    font-size: 16px;
    text-align: justify;
    color: var(--black30);
    width: max-content;
  }

  .error {
    display: block;
    min-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: var(--red);
    visibility: visible;
  }
}
