.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--black);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: visible;
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
}

.close {
  position: absolute;
  top: 40px;
  right: 40px;
  font-family: Tenor Sans;
  font-size: 16px;
  line-height: 19px;
  color: var(--white);
  text-transform: uppercase;
}

.hide {
  visibility: hidden;
  height: 0;
}