.container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  z-index: 8;
  background: var(--white70);
  backdrop-filter: blur(20px);
  transition: 0.2s;
  visibility: visible;

  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hideMenu {
  visibility: hidden;
  opacity: 0;
  transition: 0.2s;
}

.links {
  margin-top: -12vmin;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  flex-grow: 1;
  justify-content: center;
}

.link {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: var(--black);
  text-align: center;
  margin-bottom: 20px;
}


@media (min-width: 768px) {
  .container {
    padding: 40px;
  }

  .link {
    font-size: 22px;
  }
}
