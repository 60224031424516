.container {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 900px;
  min-height: 200px;
  padding: 20px;
  background: var(--yellow);
  outline: none;
  position: absolute;
}

.closeBtn {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  background: url('../../img/removeXblack.svg') no-repeat bottom center;
  background-size: 85%;
}

.openBtn {
  font-family: Jost;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: var(--black);
  background: var(--yellow);
  padding: 2px 16px;
  margin-left: auto;
  border-radius: 50px;
}

.container h2 {
  font-family: Jost;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  color: var(--black);
  margin: 0 0 26px;
}

.btns {
  margin: 12px 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.desc {
  line-height: 150%;
  font-size: 14px;
}

.btnOutline,
.btn {
  font-family: Jost;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 40px;
  background: var(--black);
  color: var(--yellow);
  margin: 14px 0 0 0;
  width: 100%;
}

.btnOutline {
  background: var(--yellow);
  border: 2px solid var(--black);
  color: var(--black);
}

@media (min-width: 1024px) {
  .container {
    padding: 40px;
  }

  .closeBtn {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 30px;
    height: 30px;
    background: url('../../img/removeXblack.svg') no-repeat top center;
    background-size: 55%;
  }

  .container h2 {
    font-family: Jost;
    font-weight: 600;
    font-size: 22px;
    line-height: 130%;
    color: var(--black);
    margin: 0 0 40px;
  }

  .btns {
    flex-direction: row;
  }

  .desc {
    font-size: 16px;
  }

  .btns {
    justify-content: flex-end;
    margin: 20px 0 0 0;
  }

  .btnOutline,
  .btn {
    margin: 0 0 0 20px;
    max-width: 200px;
  }
}
