.container {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: var(--yellow);
}

.wrapper {
  padding: 60px 20px 40px;
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  row-gap: 60px;
  justify-content: center;
  align-items: center;
}

.header {
  font-family: Jost;
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 100%;
  color: var(--black);
  text-align: center;
  margin-bottom: 20px;
}

.contacts {
  margin: 0 auto;
  width: max-content;
  display: grid;
  grid-auto-flow: row;
  position: relative;
}

.contact {
  display: flex;
  padding: 10px;
  align-items: flex-end;
  position: relative;
}

.label {
  display: block;
  width: 70px;
  text-align: right;
  margin-right: 30px;
  font-family: Tenor Sans;
  font-size: 14px;
}

.data {
  max-width: 170px;
  font-family: Jost;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  color: var(--black);
}

.contact a:hover {
  color: var(--white);
}

.subscrDesk,
.subscrMobile {
  position: relative;
  width: 290px;
  height: 54px;
  background: var(--black);

  font-family: Jost;
  font-weight: 800;
  font-size: 19px;
  text-transform: lowercase;
  color: var(--yellow);

  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 4px;
}

.subscrDesk {
  display: none;
}
.subscrMobile {
  display: inline-flex;
  margin: 0 auto;
}

.socials {
  margin: 0 auto;
}

.socialLink {
  font-family: Jost;
  text-transform: uppercase;
  font-size: 14px;
  margin-right: 20px;
}

.socialLink img {
  width: 40px;
}

.socialLink:last-child {
  margin-right: 0;
}

.recaptcha {
  text-align: center;
  font-family: Jost;
  font-size: 12px;
  font-weight: 400;
  color: var(--black50);
}

.recaptcha a {
  color: var(--black50);
  text-decoration: underline;
}

@media (min-width: 768px) {
  .container {
    height: auto;
    min-height: auto;
    max-height: auto;
  }

  .header br:nth-of-type(2) {
    display: none;
    text-align: left;
  }

  .contacts {
    position: relative;
  }

  .contact {
    padding: 12px;
  }

  .label {
    font-size: 16px;
  }

  .data {
    font-size: 18px;
  }

  .subscrDesk,
  .subscrMobile {
    width: 290px;
    height: 54px;
  }
}

@media (min-width: 1024px) {
  .container {
    padding: 40px;
    justify-content: center;
  }

  .wrapper {
    row-gap: 80px;
  }

  .header br {
    display: none;
  }

  .contacts {
    width: 100%;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: column;
    align-items: end;
  }

  .contact a:hover {
    color: var(--yellow);
  }

  .label {
    font-size: 16px;
  }

  .data {
    font-size: 18px;
  }

  .subscrDesk,
  .subscrMobile {
    position: absolute;
    top: 10px;
    right: 0;
  }

  .subscrDesk {
    display: inline-flex;
  }

  .subscrMobile {
    display: none;
  }
}
