.container {
  margin: 20px 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.textarea,
.input {
  outline: none;
  background: none;
  border: none;
  border-bottom: 1px solid var(--black05);
  margin: 2px 0 2px;
  padding: 3px 0;

  font-family: Tenor Sans;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: -0.03em;
  border-radius: 0;
}
.textarea::placeholder,
.input::placeholder {
  color: var(--black20);
}

.textarea {
  min-height: 120px;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.error,
.label {
  font-family: Jost;
  font-weight: normal;
  font-size: 16px;
  color: var(--black50);
  width: max-content;
  word-break: break-word;
  width: 100%;
}

.error {
  display: block;
  min-height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: var(--red);
  visibility: visible;
}

.noError {
  visibility: hidden;
}

.inputError,
.labelError {
  color: var(--red);
  border-color: var(--red);
}

@media(min-width: 1024px) {
  .container {
    margin: 30px 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .input {
    outline: none;
    background: none;
    border: none;
    border-bottom: 1px solid var(--black05);
    height: 32px;
    margin: 2px 0 6px;
    padding: 3px 0;
  
    font-family: Tenor Sans;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.03em;
  }
  
  .input::placeholder {
    color: var(--black20);
  }
  
  .error,
  .label {
    font-family: Jost;
    font-weight: normal;
    font-size: 16px;
    text-align: justify;
    color: var(--black50);
    width: max-content;
  }
  
  .error {
    display: block;
    min-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: var(--red);
    visibility: visible;
  }
  
  .noError {
    visibility: hidden;
  }
  
  .inputError,
  .labelError {
    color: var(--red);
    border-color: var(--red);
  }
  
}