.container {
  margin: 20px 0 0;
  display: grid;
  flex-direction: column;
}

.frames {
  display: flex;
}

.btn {
  width: 158px;
  height: 203px;
  font-family: Jost;
  font-size: 18px;
  font-weight: 600;
  color: var(--black40);
  flex-shrink: 0;
  border-radius: 3px;
  background: url('../../img/download.svg') no-repeat bottom 90px center;
  background-size: 30%;
  background-color: var(--black05);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 60px 0;
}

.btn:hover span {
  transform: scale(1.1);
}

.imgContainer {
  width: 158px;
  height: 203px;
  border-radius: 3px;
  border: 1px solid var(--black10);
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
}

.imgContainer img {
  width: 158px;
  height: 203px;
  object-fit: cover;
  background-color: var(--black20);
}

.btnRemove {
  padding: 4px;
  width: 30px;
  height: 30px;
  color: var(--red);
  font-size: 0;
  background: url('../../img/removeX.svg') no-repeat center center;
  position: absolute;
  right: 2px;
  top: 2px;
  background-size: 60%;
}

.imageFrame {
  width: 158px;
  height: 203px;
  margin-left: 20px;
  background: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border-radius: 3px;
  border: 1px solid var(--black10);
  position: relative;
  overflow: hidden;
}

.imageFrame img {
  width: 100%;
  height: 100%;
}

.imageFrame span {
  position: absolute;
  bottom: 0px;
  font-family: Jost;
  font-size: 12px;
  font-weight: 700;
  display: block;
  background: var(--black40);
  width: 100%;
  text-align: center;
  color: var(--white);
  padding: 4px 0;
}

.info {
  display: flex;
  flex-direction: column;
  font-family: Jost;
  color: var(--black50);
  font-size: 14px;
}

.infoText {
  font-size: 15px;
}

.infoText a {
  color: var(--black);
  font-weight: 500;
  text-decoration: underline;
}

.errorMobile,
.errorDesktop {
  font-family: Jost;
  font-weight: normal;
  font-size: 16px;
  color: var(--black50);
  font-size: 12px;
  font-weight: 400;
  color: var(--red);
  visibility: visible;
  
  width: 100%;
  min-height: 36px;
  display: inline-flex;
  align-items: center;
}

.errorDesktop {
  display: none;
}

.btnError {
  color: var(--red);
  border: 1px solid var(--red);
}

@media (min-width: 1024px) {
  .container {
    margin: 30px 0 0;
    display: flex;
    flex-direction: row;
  }

  .errorDesktop,
  .label {
    font-family: Jost;
    font-weight: normal;
    font-size: 16px;
    text-align: justify;
    color: var(--black30);
    width: max-content;
  }

  .errorDesktop {
    display: inline-flex;
    font-size: 14px;
    font-weight: 400;
    color: var(--red);
    visibility: visible;
  }

  .errorMobile {
    display: none;
  }

  .imageFrame {
    margin: 0 30px;
  }

  .imageFrame span {
    font-size: 14px;
  }
}
