.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  z-index: 9;
  align-items: center;
  transition: 0.5s;
  background: var(--white70);
  backdrop-filter: blur(5px);
}

.navbar.hideHeader {
  transition: 0.5s;
  background: transparent;
  backdrop-filter: blur(0px);
}

.navbarOpenMenu {
  background: none;
  backdrop-filter: none;
  transition: 0.3s;
}

.logoImg {
  width: 53px;
  padding: 16px 0;
  transition: 0.5s;
}

.hideHeader .logoImg {
  opacity: 0;
  transition: 0.5s;
}

.links {
  display: flex;
  height: 100%;
}

.link {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: var(--black);
  display: inline-flex;
  align-items: center;
  padding: 16px 30px;
  height: 100%;
  transition: 0.5s;
}

.hideHeader .link {
  color: var(--white);
  transition: 0.5s;
}

.link span {
  line-height: 200%;
  border-bottom: 4px solid transparent;
}

.linkActive span {
  border-bottom: 3px solid var(--yellow);
}

.menuBtn {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding: 20px 0 20px 20px;
}

.menuBtn div {
  width: 24px;
  height: 4px;
  background: var(--black);
  transition: 0.3s;
}

.hideHeader .menuBtn div {
  background: var(--white);
}

.shortLine {
  margin-bottom: 2px;
  transform-origin: top right;
}

.longLine {
  transform-origin: bottom right;
}

.menuOpen .shortLine {
  transition: 0.3s;
  transform: translateY(-5px) rotate(-45deg);
}

.menuOpen .longLine {
  width: 24px;
  transition: 0.3s;
  transform: translateY(5px) rotate(45deg);
}

.links {
  display: none;
}

@media (max-height: 375px) {
}

@media (min-width: 768px) {
  .colorZone {
    background: var(--yellow);
    padding: 14px 14px 12px;
    margin: 40px;
  }

  .logoImg {
    width: 50px;
  }

  /*   
  .shortLine {
    width: 40px;
    height: 4px;
    margin-bottom: 9px;
  }
  
  .longLine {
    width: 60px;
    height: 4px;
  }
  
  .menuOpen .shortLine {
    transform: translateY(-7px) rotate(-45deg)
  }
  
  .menuOpen .longLine {
    width: 40px;
    transform: translateY(7px) rotate(45deg)
  } */
}

@media (min-width: 1024px) {
  .navbar {
    padding: 0 40px;
  }

  .logoImg {
    width: 70px;
    padding: 16px 0;
  }

  .links {
    display: flex;
  }

  .link:first-child {
    display: none;
  }

  .menuBtn {
    display: none;
  }
}
