@import url('https://fonts.googleapis.com/css2?family=Cuprum:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Jost:wght@300;400;500;600;700;800;900&family=Tenor+Sans&display=swap');

html {
  min-width: 320px;
  background: var(--white);
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a,
button {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
}

a:active,
button:active {
  opacity: 0.6;
}

body {
  margin: 0;
  font-family:  'Tenor Sans', -apple-system,
    BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--white);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grecaptcha-badge {
  visibility: hidden;
}

:root {
  --red: #ff593f;
  --red05: #ff593f20;
  --blue: #3245FE;
  --yellow: #FFC900;
  --green: #33AB79;
  --white: #ffffff;
  --white70: rgba(255, 255, 255, 0.7);
  --black: #000000;
  --black90: rgba(0, 0, 0, 0.9);
  --black80: rgba(0, 0, 0, 0.8);
  --black70: rgba(0, 0, 0, 0.7);
  --black60: rgba(0, 0, 0, 0.6);
  --black50: rgba(0, 0, 0, 0.5);
  --black40: rgba(0, 0, 0, 0.4);
  --black30: rgba(0, 0, 0, 0.3);
  --black20: rgba(0, 0, 0, 0.2);
  --black10: rgba(0, 0, 0, 0.1);
  --black05: rgba(0, 0, 0, 0.05);
  --black04: rgba(0, 0, 0, 0.04);
  --black03: rgba(0, 0, 0, 0.03);
  --black02: rgba(0, 0, 0, 0.02);
  --black01: rgba(0, 0, 0, 0.01);
  --bckgGray: #f8f8f8;
}
