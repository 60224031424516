.main {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
}

.head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  max-width: 550px;
  margin-bottom: 36px;
}

.header {
  font-family: Jost;
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 100%;
  color: var(--black);
  text-align: left;
}

.desc {
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: var(--black);
  margin-bottom: 60px;
  padding: 0 20px;
  max-width: 550px;
}

.desc span {
  display: block;
  margin-bottom: 8px;
}

.desc span b {
  background: var(--yellow);
}

.desc a {
  display: inline-flex;
  background: var(--black05);
  padding: 0 8px;
  color: var(--black);
  font-family: Jost;
  font-weight: 500;
}

.form {
  width: 100%;
  background: var(--white);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  max-width: 550px;
  margin: 0 auto;
  padding: 12px 0 0;
}

.inputsTwoOne,
.inputs {
  width: 100%;
  display: block;
}

.field {
  padding: 26px;
  width: 100%;
}

.fieldName {
  font-family: Jost;
  font-weight: bold;
  font-size: 20px;
  line-height: 130%;
  color: var(--black);
  margin-bottom: 16px;
}

.info {
  display: block;
  margin: 0px 0 30px;
  font-family: Jost;
  color: var(--black30);
  font-size: 14px;
}

.passportHeader {
  display: flex;
  align-items: baseline;
}

.disclaimer {
  font-family: Tenor Sans;
  font-size: 10px;
  line-height: 150%;
  color: var(--black40);
  margin: 0 auto;
  text-align: center;
}

.submitWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  margin-bottom: 10vh;
}

.actionBtn {
  font-family: Jost;
  font-weight: 600;
  font-size: 16px;
  padding: 12px 26px;
  flex-shrink: 0;
  background: var(--yellow);
  color: var(--black);
  height: max-content;
  margin: 0 auto 40px;
  position: relative;
}

.loader {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  overflow: hidden;
}

.loader div {
  width: 100%;
  height: 100%;
  background: var(--black);
  animation: loader 2s infinite;
}

.subscription {
  display: flex;
  color: var(--black);
  font-family: Tenor Sans;
  font-size: 14px;
  line-height: 150%;
}

.checkbox {
  width: 24px;
  height: 16px;
  border-bottom: 4px solid var(--yellow);
  border-left: 4px solid var(--yellow);
  transform: rotate(-45deg);
  margin-right: 16px;
}

.checkboxHide {
  opacity: 0;
}

.checkboxTextLine {
  text-decoration-line: line-through;
}

.temp {
  display: none;
  width: 140px;
  justify-content: space-between;
}

.temp button {
  color: var(--black20);
}

.disabled {
  color: var(--black20);
}

.addPassport {
  font-family: Jost;
  font-weight: 600;
  font-size: 16px;
  padding: 12px 36px;
  flex-shrink: 0;
  background: var(--yellow);
  color: var(--black);
  margin-left: 20px;
  height: max-content;
}

.gray {
  background: var(--black03);
  padding: 12px 0;
}

@media (min-width: 1024px) {
  .main {
    padding-top: 160px;
  }

  .head {
    display: flex;
    justify-content: space-between;
    max-width: 1024px;
    padding: 0 24px;
    margin: 0 auto;
    margin-bottom: 63px;
  }

  .header {
    font-size: 56px;
    text-align: left;
    padding: 0;
  }

  .desc {
    font-size: 16px;
    width: 1000px;
    max-width: 100%;
    margin: 0 auto 60px;
    padding: 0;
    max-width: 1024px;
    padding: 0 24px;
  }

  .form {
    max-width: 100%;
    width: 1000px;
    margin: 0 auto;
    max-width: 976px;
    padding: 26px 0 0;
  }

  .field {
    padding: 32px 52px;
  }

  .fieldName {
    margin-bottom: 10px;
  }

  .disclaimer {
    width: 300px;
  }

  .submitWrapper {
    margin: 0 0 40px;
  }

  .submit {
    width: 279px;
    height: 54px;
  }

  .inputs {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    column-gap: 40px;
  }

  .inputsTwoOne {
    display: grid;
    grid-template-columns: calc(60% + 40px) 30%;
    column-gap: 40px;
  }

  .actionBtn {
    padding: 12px 36px;
  }

  .gray {
    padding: 26px 0;
  }

  .info {
    margin: 0 0 10px;
  }
}

@keyframes loader {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
