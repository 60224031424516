.container {
  width: 100%;
  padding-top: 120px;
}

.header {
  font-family: Jost;
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 100%;
  color: var(--black);
  text-align: left;
  margin: 0 26px 63px;
}

.scrollContainer {
  width: 100%;
  position: relative;
  margin-bottom: 100px;
}

.videos {
  overflow-x: scroll;
  width: 100%;
  display: flex;
}

.video {
  width: 95vmin;
  flex-shrink: 0;
  transition: 0.1s;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: 2px;
  z-index: 3;
}

.player {
  width: 100%;
  height: calc(95vmin * 0.7);
  transition: 0.2s;
}

.title {
  font-family: Jost;
  font-weight: bold;
  font-size: 14px;
  line-height: 130%;
  color: var(--black);
  margin-bottom: 14px;
  margin-top: 10px;
}

.photos {
  height: calc(190vmin * 0.7);
  max-height: 90vh;
  display: grid;
  grid-auto-columns: calc(70vh / 2 / 7 * 10);
  grid-template-rows: repeat(2, 50%);
  grid-auto-columns: 95vmin;
  grid-auto-flow: column;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 2px;
}

.photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(1.1) grayscale(1);
  transition: 0.3s;
}

.photo:hover {
  transition: 0.3s;
  filter: brightness(1.1)  grayscale(0);
}

.titleLine {
  width: 100%;
  overflow: hidden;
  margin: 2px 0;
  background: var(--black);
  color: var(--white);

  font-family: Jost;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 130%;
  text-transform: uppercase;
  padding: 2px;
}

.titleLine span {
  margin: 0 100px;
}

@media (min-width: 768px) {
  .scrollContainer {
    margin-bottom: 160px;
  }

  .photos {
    height: calc(180vmin * 0.7);
    max-height: 70vh;
    grid-auto-columns: calc(70vh / 2 / 7 * 10);
  }

  .photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
  }

  .video {
    /* width: 80vmin; */
    width: calc(70vh / 2 / 7 * 10);
    height: calc(90vmin * 0.7 / 2);
  }

  .player {
    height: calc(90vmin * 0.7 / 2);
  }
}

@media (min-width: 1024px) {
  .header {
    font-size: 56px;
  }
}
